<template>
    <div class="flex w-100">
        <header-menu :login="true"></header-menu>
        <div class="flex w-100 center-xs middle-xs">
            <div class="flex mt-30 col signUpForm middle-xs" :class="isMobileView ? 'w-90' : 'w-40'">
                <div class="flex createAct pl-10 pr-10 mb-30">CREATE ACCOUNT</div>
                <div class="flex w-100 mt-5 mb-5">
                     <input class="w-100 pt-15 pb-15 pl-20 inputEle" type="text" placeholder="Your Name" v-model="data.name">
                </div>
                <div class="flex w-100 mt-5 mb-5">
                     <input class="w-100 pt-15 pb-15 pl-20 inputEle" type="text" placeholder="Your Email" v-model="data.email" @focusout="validateEmail()">
                     <div  v-if="data.isInvalidEmail" class="flex error mt-5 p-5">Not a valid Email</div>
                </div>
                <div class="flex w-100 mt-5 mb-5">
                     <input class="w-100 pt-15 pb-15  pl-20 inputEle" type="password" placeholder="Password" v-model="data.password" @focusout="validatePassword()">
                     <div  v-if="data.isInvalidPassword" class="flex error mt-5 p-5">Password must contain a minimum of eight characters, at least one uppercase letter, one lowercase letter, and one number.</div>
                </div>
                <div class="flex w-100 mt-5 mb-5">
                     <input class="w-100 pt-15 pb-15  pl-20 inputEle" type="password" placeholder="Repeat Your Password" v-model="data.rePassword" @focusout="validateRePassword()" v-on:keyup.enter="signUp()">
                    <div  v-if="data.isPasswordMismatch" class="flex error mt-5 p-5">Password mismatch</div>
                </div>
               <!-- <div class="flex w-100 row ml-0 mr-0 pt-15 pb-15">
                   <input type="checkbox" name="agree">
                   <label for="agree" class="ml-5 remCheck"> I agree all statements In terms of service</label>
               </div> -->
                <div  v-if="data.isEmailExists" class="flex error mt-5 p-5">Email already registered! Try login</div>
                <div  v-if="!data.isValidData" class="flex error mt-5 p-5">All the fields are required!</div>
               <div class="flex w-100 pt-15 pb-15 pl-20 mt-30 center-xs middle-xs buttonC" @click="data.registerVisible = true">SIGN UP</div>
            </div>
        </div>
    </div>
    <register-popup v-show="data.registerVisible" @close="data.registerVisible = false"></register-popup>
</template>
<script setup>
import { HeaderMenu, RegisterPopup } from "@/components"
import { isMobile, emailValidator, passwordValidator } from "@/lib/utils"
import { computed, reactive } from 'vue'
import router from "@/lib/router.js"
import { post } from "@/lib/api.js"

//const name = "Register"
const data = reactive({
  isInvalidEmail: false,
  isInvalidPassword: false,
  isEmailExists: false,
  isPasswordMismatch: false,
  isValidData: true,
  name: '',
  email: '',
  password: '',
  rePassword: '',
  registerVisible: false,
  validation: function() {
      return (this.name && this.email && this.password && this.rePassword) ? true : false
  }
})
const isMobileView = computed(() => {
    return isMobile()
})
async function signUp() {
    if (!data.validation()) {
        data.isValidData = false
        return
    }
    data.isValidData = true
    if(data.passwordMismatch || data.invalidEmail || data.invalidPassword) {
        return
    }
    try {
        const user = await post('users', false, data);
        if(user.status == "success") {
            router.push({path: '/'})
        }
    } catch (error) {
        console.error('error signing up:', error);
        if (error.response.data.errors.includes("emailExists")) data.isEmailExists = true
    }
}
function validateEmail() {
    data.isInvalidEmail = !emailValidator(data.email)
}
function validatePassword() {
    data.isInvalidPassword = !passwordValidator(data.password)
}
function validateRePassword() {
    data.isPasswordMismatch = data.password !== data.rePassword
}
</script>
<style lang="scss" scoped>
.buttonC {
    border-radius: 10px;
}

.createAct {
    font-family: Bold;
    font-size: 20px;
    color: #000;
}
</style>
